import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

class NavigationSet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false
        };

        this.handleCollapseSetClick = this.handleCollapseSetClick.bind(this);
    }

    handleCollapseSetClick(event) {
        this.setState({ collapsed: !this.state.collapsed })
    }

    render() {
        let parentItem = this.props.parent;
        let className = this.state.collapsed ? `set collapsed` : `set`;
        return (
            <li className={className}>
                <Link className="nav_set_parent" to={parentItem.link} onClick={this.handleCollapseSetClick}>
                    {parentItem.title}
                </Link>
                {this.props.children}
            </li>
        );
    }
}

class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobileMenuOpen: false,
        };

        this.handleMobileMenuClick = this.handleMobileMenuClick.bind(this);
    }

    handleMobileMenuClick(event) {
        this.setState({
            mobileMenuOpen: !this.state.mobileMenuOpen
        });
    }

    renderLink(linkItem, idx) {
        let href = linkItem.link || '#';
        return (
            <li key={linkItem.slug}>
                <NavLink to={`/${href}`} exact activeClassName="active">
                    {linkItem.title}
                </NavLink>
            </li>
        );
    }

    renderSet(parentItem, childrenArr, idx) {
        // render parent link
        // open UL nav_set
        // map childrenArr and add li
            // if li has children, recursive renderNav again
        // close UL nav_set
        return (
            <NavigationSet
                parent={parentItem}
                children={childrenArr}
                key={parentItem.slug}>
                {(childrenArr && childrenArr.length > 0) &&
                    <ul className="nav_set" id={parentItem.slug}>
                        {childrenArr.map((linkItem, cIdx) => {
                            let newIdx = `${idx}${cIdx}`
                            return this.renderNav(linkItem, newIdx);
                        })}
                    </ul>
                }
            </NavigationSet>
        );
    }

    renderNav(linkItem, idx) {
        if (linkItem) {
            if (linkItem.link && linkItem.link.indexOf('http') > -1) {
                return (
                    <li key={linkItem.slug}>
                        <a rel="noopener noreferrer"
                            href={linkItem.link}>
                                {linkItem.title}
                        </a>
                    </li>
                );
            } else if (linkItem.link === '#' && linkItem.children) {
                return this.renderSet(linkItem, linkItem.children, idx);
            } else {
                return this.renderLink(linkItem, idx);
            }
        }
    }

    render() {
        const navItems = this.props.items || [];

        let navMobileIconState = this.state.mobileMenuOpen ? `open` : `closed`;
        let navMobileIconClass = `nav_mobile_icon ${navMobileIconState}`;
        let navMainClass = `nav_main ${navMobileIconState}`;
        return (
            <nav className="navigation" style={this.props.style}>
                <div className="nav_mobile" onClick={this.handleMobileMenuClick}>
                    <div className={navMobileIconClass}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="nav_mobile_title">Menu</div>
                </div>
                <ul className={navMainClass}>
                {navItems.length > 0 && navItems.map((linkItem, idx) => {
                    // if item is set start ul
                        // next item is array, loop through and close ul at end
                    
                    return this.renderNav(linkItem, idx);
                })}
                </ul>
            </nav>
        );
    }

}

export default Navigation;
