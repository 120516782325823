import axios from 'axios';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Navigation from './components/Navigation';

import Page from './views/Page';

import './App.css';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      settings: {
        heading: '',
        subheading: '',
        startPage: null
      },
      navigation: {},
    }
  }

  componentDidMount() {
    // get data from keystone API route
    // axios.get('/api/settings')
    // console.log(window.location.pathname);
    // axios.get('http://3c9ed77a.ngrok.io/api/settings')
    axios.get('/api/settings')
      .then(res => {
        if (res.data.status === 'success') {
          const { navigation, ...settings } = res.data.settings;

          this.setState({
            settings, 
            navigation
          });
        }

        // determine start page- if there is a route in the URL, try to load that
        // otherwise load what's comes from the API
        // if no default start page, load empty page
        
      });
  }

  setSubheading() {
    return { __html: this.state.settings.subheading || '' };
  }

  render() {
    return (
      <div className="App">
        <header>
          <div className="header_title">
            <div className="heading">
              <a href="/">{this.state.settings.heading}</a>
            </div>
            <div
              className="subheading"
              dangerouslySetInnerHTML={this.setSubheading()}
            />
          </div>
          <Navigation items={this.state.navigation} />
        </header>
        <main>
          <Switch>
            <Route exact path="/">
              {this.state.settings.startPage ?
                <Redirect exact from="/" to={`/${this.state.settings.startPage}`} />
                :
                <div></div>
              }
            </Route>
            
            {this.state.settings.startPage && 
              <Route exact path="/:slug" component={Page}></Route>
            }
          </Switch>
        </main>
      </div>
    );
  }

}

export default App;
