import qs from 'qs';
import axios from 'axios';
import React, { Component } from 'react';
import ReactLoading from 'react-loading';

import ImageWithCaption from '../components/ImageWithCaption';

class Page extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: null,
            images: null,
            loading: true,
            auth: false,
            password: '',
            passwordIncorrect: false,
        };

        this.handleAuthFormSubmit = this.handleAuthFormSubmit.bind(this);
        this.handleInputPasswordChange = this.handleInputPasswordChange.bind(this);
    }

    componentDidMount() {
        this.fetchPage(this.props);
    }

    componentWillReceiveProps(nextProps) {
        // page route has changed
        // console.log(nextProps.location.pathname, this.props.location.pathname);

        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                content: null,
                images: null,
                loading: true,
                auth: false,
            });
            this.fetchPage(nextProps);
        }
    }

    fetchPage(props) {
        // load page from keystone API route, check for password
            // if password, show auth form
            // else load page immediately

        // axios.get('/api/page/:')

        let pageSlug = props.match.params.slug;
        let apiCall = `/api/page/${pageSlug}`;

        // axios.get(`http://3c9ed77a.ngrok.io${apiCall}`)
        axios.get(apiCall)
        .then(res => {
            let data = res.data;
            // console.log('page:', res.data);

            if (data.status === 'success') {
                this.loadPage(data.page);
                
            } else if (data.status === 'auth') {
                // show auth form
                this.setState({
                    content: null,
                    images: null,
                    loading: false,
                    auth: true
                });
            } else {
                this.setState({
                    content: 'Page Not Found',
                    images: null,
                    loading: false,
                    auth: false,
                });
            }
        })
        .catch(err => {
            // console.log(err);
            this.setState({
                content: 'Unable To Load Page',
                images: null,
                loading: false,
                auth: false,
            });
        });
    }

    loadPage(page) {
        // console.log('loading page:', page);
        if (page.content || page.content === '') {
            this.setState({
                content: page.content,
                images: null,
                loading: false,
                auth: false,
                password: '',
                passwordIncorrect: false,
            });
        } else if (page.images) {
            this.setState({
                content: null,
                images: page.images,
                loading: false,
                auth: false,
                password: '',
                passwordIncorrect: false,
            });
        } else {
            this.setState({
                content: 'Page Not Found',
                images: null,
                loading: false,
                auth: false,
                password: '',
                passwordIncorrect: false,
            });
        }
    }

    setContent() {
        return { __html: this.state.content || '' };
    }

    handleAuthFormSubmit(event) {
        let inputPass = this.state.password;
        if (inputPass) {
            let pageSlug = this.props.match.params.slug;
            let apiCall = `/api/page/${pageSlug}`;

            // axios.post(`http://3c9ed77a.ngrok.io${apiCall}`,
            axios.post(apiCall,
            qs.stringify({
                password: inputPass
            }), {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then(res => {
                if (res.data.status === 'success') {
                    this.loadPage(res.data.page);
                } else {
                    this.setState({
                        passwordIncorrect: true
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    content: 'Unable To Load Page',
                    images: null,
                    loading: false,
                    auth: false,
                });
            })
        }
        event.preventDefault();
    }

    handleInputPasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    render() {
        //dangerouslySetInnerHTML for wysiwyg content
        // OR load image gallery for images
        // console.log(this.props);
        return (
            <div>
                {this.state.loading === true &&
                    <ReactLoading
                        type={`spin`}
                        color={`#CCC`}
                        className="loading"
                        width={16}
                        height={16}
                    />
                }
                {this.state.content &&
                    <div className="page" dangerouslySetInnerHTML={this.setContent()}></div>
                }
                {(this.state.images && this.state.images.length > 0) && (
                    <div className="project">
                        {this.state.images.map((image, idx) =>
                            <ImageWithCaption image={image} key={idx} />
                        )}
                    </div>
                )}
                {this.state.auth &&
                    <div className="password">
                        <form className="form_password" onSubmit={this.handleAuthFormSubmit}>
                            <label>Enter Password</label>
                            <input
                                type="password"
                                name="input_password"
                                value={this.state.password}
                                onChange={this.handleInputPasswordChange}
                                autoComplete="off"
                                className={this.state.passwordIncorrect ? `incorrect` : ``}
                            />
                            <div>For access contact:<br/>studio@matthewdodd.com</div>
                        </form>
                    </div>
                }
            </div>
        );
    }

}

export default Page;