import React, { Component } from 'react';

class ImageWithCaption extends Component {

    render() {
        return (
            <div className="image_wc">
                <img src={this.props.image.secure_url} alt={this.props.image.caption} />
                <span className="caption">{this.props.image.caption}</span>
            </div>
        )
    }

}

export default ImageWithCaption;
